
    /deep/ p {
        margin: 0;
    }
    .student-breadcrumb{
        font-size: 14px;
    }
    .goodsPurchase-container {
        background: #fff;
        padding: 20px;
        min-height: calc(100vh - 194px);
        /*height: calc(100vh - 191px);*/
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
    }
    .goodPurchase-main {
        display: flex;
        flex: 1;
        height: 1%;
        .goodPurchase-item {
            display: flex;
            flex-direction: column;
            &.item-left {
                width: 1%;
                flex: 1;
            }
            &.item-right {
                width: 45%;
                margin-left: 20px;
            }
            .item-head {
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-bottom: -1px;
                .head-tab {
                    width: 100px;
                    box-sizing: border-box;
                    height: 36px;
                    border: 1px solid #DCDFE6;
                    text-align: center;
                    line-height: 36px;
                }
                .head-operate {
                    .el-button {
                        border-radius: 0;
                    }
                    .el-select {
                        /deep/ .el-input__inner {
                            border-radius: 0;
                        }
                    }
                }
            }
        }
    }
    .el-link + .el-link {
        margin-left: 20px;
    }
    /* S 表格*/
    /deep/ .el-table {
        display: flex;
        flex-direction: column;
        .el-checkbox__input.is-checked .el-checkbox__inner,
        .el-checkbox__input.is-indeterminate .el-checkbox__inner {
            background-color: #FD4446;
            border-color: #FD4446;
        }
        .el-checkbox__inner:hover {
            border-color: #FD4446;
        }
        .el-checkbox__input.is-focus .el-checkbox__inner {
            border-color: #FD4446;
        }
        .el-table__body-wrapper {
            height: 1%;
            flex: 1;
        }
        /*&.library-table {*/
        /*    .el-table__body-wrapper {*/
        /*        height: 450px;*/
        /*        overflow: auto;*/
        /*    }*/
        /*}*/
        .el-table__footer-wrapper {
            tbody {
                td {
                    background-color: #f8f8f8;
                }
            }
        }
        .good-title {
            color: #333;
            transition: all .3s;
            cursor: pointer;
            &:hover, &:focus {
                color: #FD4446;
            }
        }
    }
    /* E 表格*/
    /*分页*/
    /deep/ .pager-center {
        background: #F8F8F8;
        border: 1px solid #EBEEF5;
        border-top: 0;
        padding: 5px;
        .el-pager li {
            background: transparent;
        }
        &.el-pagination button:disabled {
            background-color: transparent;
        }
        &.el-pagination .btn-next, &.el-pagination .btn-prev {
            background: center center no-repeat transparent;
        }
    }
    .page-title {
        font-size: 20px;
        font-family: SourceHanSansCN-Medium, SourceHanSansCN;
        font-weight: 500;
        color: #333;
        margin: 20px 0;
        line-height: 1;
        .balance {
            font-size: 18px;
            color: #FD4446;
            font-family: Avenir, Helvetica, Arial, sans-serif;
            .iconfont {
                font-size: 12px;
                margin: 0 4px;
            }
        }
    }
    .bottom-btn {
        margin-top: 20px;
    }

    .mask-cover {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, .5);
        /*background: rgba(255, 255, 255, .5);*/
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 99;
        .prompt {
            color: #fff;
            font-size: 30px;
            .iconfont {
                font-size: 30px;
                margin-right: 10px;
            }
        }
    }
